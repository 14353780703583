var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pt-2"
  }, [_vm.editStep === _vm.EditSteps.INITIAL ? _c('v-row', [_vm.showSave ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('document-template-detail-save', {
    ref: "save",
    attrs: {
      "disabled": _vm.disabled,
      "hidden": "",
      "document": _vm.document
    }
  }), _c('v-btn', {
    attrs: {
      "block": "",
      "color": "success",
      "elevation": 0,
      "disabled": _vm.disabled,
      "loading": _vm.loading,
      "height": 56
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("sign.DocumentTemplateDetailSave.save")) + " ")])], 1) : _vm._e(), _vm._l(_vm.options, function (option) {
    return _c('v-col', {
      key: option.icon,
      attrs: {
        "cols": _vm.showPlaceholderOption ? 4 : 6
      }
    }, [!_vm.isMobile ? _c('tooltip', {
      attrs: {
        "text": option.text
      }
    }, [_c('v-btn', {
      attrs: {
        "disabled": option.disabled || _vm.disabled,
        "color": "primary",
        "elevation": "0",
        "block": ""
      },
      on: {
        "click": option.action
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "white"
      }
    }, [_vm._v(" " + _vm._s(option.disabled ? "mdi-lock" : option.icon) + " ")]), _c('span', [_vm._v(" " + _vm._s(option.short) + " ")])], 1)], 1) : _vm._e()], 1);
  })], 2) : _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('document-template-detail-save', {
    ref: "save",
    attrs: {
      "document": _vm.document,
      "hidden": ""
    }
  }), _c('v-btn', {
    attrs: {
      "block": "",
      "color": "success",
      "elevation": 0,
      "disabled": _vm.disabled,
      "loading": _vm.loading,
      "height": 56
    },
    on: {
      "click": _vm.addField
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("sign.ReportDocumentSignRequestStepperForm.confirmTokenShort")) + " ")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "elevation": 0,
      "outlined": "",
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.discard
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("sign.DocumentTemplateDetailTokenList.discard")) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }