import { render, staticRenderFns } from "./CostCardDocuments.vue?vue&type=template&id=442891b0&scoped=true&"
import script from "./CostCardDocuments.vue?vue&type=script&lang=ts&"
export * from "./CostCardDocuments.vue?vue&type=script&lang=ts&"
import style0 from "./CostCardDocuments.vue?vue&type=style&index=0&id=442891b0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "442891b0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VFlex,VHover,VIcon,VListItem,VListItemAction,VListItemContent,VSkeletonLoader,VSpacer})
