var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-actions', {
    staticClass: "mb-n6"
  }, [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.searchOption,
      callback: function callback($$v) {
        _vm.searchOption = $$v;
      },
      expression: "searchOption"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('components.partner.PartnerReportDetail.SendToKsrDialog.numberplate'),
      "value": _vm.SearchOptions.NUMBERPLATE
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('components.partner.PartnerReportDetail.SendToKsrDialog.jobId'),
      "value": _vm.SearchOptions.JOB_ID
    }
  })], 1)], 1), _c('tooltip', {
    attrs: {
      "text": _vm.$t('components.partner.PartnerReportDetail.SendToKsrDialog.searchNumberPlateOrJobHint')
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.loadJobs.apply(null, arguments);
      },
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.loadJobs.apply(null, arguments);
      }
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "type": _vm.searchOption === _vm.SearchOptions.JOB_ID ? 'number' : 'text',
      "label": _vm.$t('components.partner.PartnerReportDetail.SendToKsrDialog.search'),
      "hint": _vm.$t('confirmSearchWithEnter'),
      "loading": _vm.isExistingJobsLoading,
      "append-icon": "mdi-magnify",
      "persistent-hint": ""
    },
    on: {
      "click:append": _vm.loadJobs
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1)], 1)], 1), _vm.isExistingJobsLoading ? _c('v-col', [_c('v-skeleton-loader', {
    attrs: {
      "type": "list-item-three-line, list-item-three-line, list-item-three-line"
    }
  })], 1) : _vm.existingJobs.length > 0 ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('v-list', [_vm._l(_vm.existingJobs, function (job) {
    return [_c('v-list-item', {
      key: job.jobId,
      on: {
        "click": function click($event) {
          return _vm.click(job);
        }
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_c('span', {
      staticClass: "mr-2"
    }, [_vm._v(_vm._s(job.numberPlate))]), job.vehicleManufacturer ? _c('span', [_vm._v(" " + _vm._s(job.vehicleManufacturer))]) : _vm._e(), job.vehicleModel ? _c('span', [_vm._v(" " + _vm._s(job.vehicleModel))]) : _vm._e(), job.vehicleType ? _c('span', [_vm._v(" " + _vm._s(job.vehicleType))]) : _vm._e()]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(job.jobId) + " - " + _vm._s(job.employee))]), _c('v-list-item-subtitle', _vm._l(job.value.involvements.involvedParties, function (party) {
      return _c('div', {
        key: "".concat(job.jobId).concat(party.id)
      }, [_c('div', {
        staticClass: "mt-1"
      }, [party.type !== undefined ? _c('span', {
        staticClass: "caption"
      }, [_vm._v(" " + _vm._s(_vm.ksrTypeMap(party.type)) + " ")]) : _vm._e()]), _c('div', [party.personalData.firstName ? _c('span', {
        staticClass: "mr-1"
      }, [_vm._v(" " + _vm._s(party.personalData.firstName) + " ")]) : _vm._e(), party.personalData.lastName ? _c('span', {
        staticClass: "mr-1"
      }, [_vm._v(" " + _vm._s(party.personalData.lastName) + " ")]) : _vm._e(), party.personalData.eMailAddresses ? _c('span', {
        staticClass: "mr-1"
      }, [_c('v-icon', {
        attrs: {
          "x-small": ""
        }
      }, [_vm._v("mdi-email")]), _vm._v(" " + _vm._s(party.personalData.eMailAddresses[0]) + " ")], 1) : _vm._e(), party.personalData.phone.mobile ? _c('span', {
        staticClass: "mr-1"
      }, [_c('v-icon', {
        attrs: {
          "x-small": ""
        }
      }, [_vm._v("mdi-phone")]), _vm._v(" " + _vm._s(party.personalData.phone.mobile) + " ")], 1) : _vm._e(), party.postalAddress.street ? _c('span', {
        staticClass: "mr-1"
      }, [_c('v-icon', {
        attrs: {
          "x-small": ""
        }
      }, [_vm._v("mdi-map-marker")]), _vm._v(" " + _vm._s(party.postalAddress.street) + ", ")], 1) : _vm._e(), party.postalAddress.zipCode ? _c('span', {
        staticClass: "mr-1"
      }, [_vm._v(" " + _vm._s(party.postalAddress.zipCode) + ", ")]) : _vm._e(), party.postalAddress.city ? _c('span', {
        staticClass: "mr-1"
      }, [_vm._v(" " + _vm._s(party.postalAddress.city) + " ")]) : _vm._e()])]);
    }), 0)], 1)], 1)];
  })], 2), _c('debug', {
    attrs: {
      "debug": _vm.existingJobs
    }
  })], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }