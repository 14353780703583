var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('debug', [_vm._v(" " + _vm._s(_vm.partnerId) + " ")]), _c('paginated-table', {
    attrs: {
      "store": _vm.store,
      "headers": _vm.headers,
      "baseQuery": {
        partnerId: _vm.partnerId
      },
      "controlElements": _vm.controlElements,
      "predefinedFilter": _vm.predefinedFilter,
      "showSelect": false,
      "groupBy": _vm.isMultipleLanguages ? 'key' : undefined,
      "itemsPerPage": _vm.itemsPerPage
    },
    on: {
      "click:row": _vm.setSelectedItem
    },
    scopedSlots: _vm._u([{
      key: "group.header",
      fn: function fn() {
        return [_c('v-spacer')];
      },
      proxy: true
    }, {
      key: "bodyActions",
      fn: function fn() {
        return [_vm._t("bodyActions", function () {
          return [_c('template-create-dialog', {
            attrs: {
              "hideButton": false,
              "partnerId": _vm.partnerId
            },
            on: {
              "created": function created(v) {
                _vm.$toast.success('👍');

                _vm.setSelectedItem(v);
              }
            }
          })];
        })];
      },
      proxy: true
    }, {
      key: "item.isPublic",
      fn: function fn(_ref) {
        var value = _ref.value;
        return [value ? _c('v-icon', [_vm._v(" mdi-check ")]) : _c('v-icon', [_vm._v(" mdi-close ")])];
      }
    }, {
      key: "item.meta.language",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm._f("getFlagEmojiByLanguage")(item.meta.language)) + " ")];
      }
    }, {
      key: "item.isAdminTemplate",
      fn: function fn(_ref3) {
        var value = _ref3.value;
        return [value ? _c('v-icon', [_vm._v(" mdi-check ")]) : _c('v-icon', [_vm._v(" mdi-close ")])];
      }
    }, {
      key: "item.meta.title",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('b', [_vm._v(_vm._s(item.meta.title))]), _vm._v(" "), _c('br'), _vm._v(" " + _vm._s(_vm.getDescription(item)) + " ")];
      }
    }, {
      key: "item.meta.categories",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [item.meta.categories.length ? _c('div', _vm._l(item.meta.categories, function (token) {
          return _c('v-chip', {
            key: token,
            staticClass: "mr-1 mt-1 mb-1",
            attrs: {
              "small": "",
              "color": "info"
            }
          }, [_vm._v(" " + _vm._s(token) + " ")]);
        }), 1) : _c('div')];
      }
    }, {
      key: "item.context",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm.getTemplateContext(item).available.length ? _c('div', [_c('tooltip', {
          attrs: {
            "text": _vm.$t('components.template.dialog.selection.availableContext')
          }
        }, _vm._l(_vm.getTemplateContext(item).available, function (cat) {
          return _c('v-chip', {
            key: cat + item.key,
            staticClass: "mr-1 mt-1 mb-1",
            attrs: {
              "small": "",
              "color": "success"
            }
          }, [_vm._v(" " + _vm._s(cat) + " ")]);
        }), 1)], 1) : _vm._e(), _vm.getTemplateContext(item).missing.length ? _c('div', [_c('tooltip', {
          attrs: {
            "text": _vm.$t('components.template.dialog.selection.unavailableContext')
          }
        }, _vm._l(_vm.getTemplateContext(item).missing, function (cat) {
          return _c('v-chip', {
            key: cat + item.key,
            staticClass: "mr-1 mt-1 mb-1",
            attrs: {
              "small": "",
              "color": "error"
            }
          }, [_vm._v(" " + _vm._s(cat) + " ")]);
        }), 1)], 1) : _vm._e()];
      }
    }], null, true)
  }), _vm.selectedItem && _vm.isOpenSideCard ? _c('template-side-card', {
    on: {
      "close": function close($event) {
        _vm.selectedItem = null;
      },
      "onDelete": function onDelete($event) {
        return _vm.store.fetchFirstPage({
          partnerId: _vm.partnerId
        });
      }
    },
    model: {
      value: _vm.selectedItem,
      callback: function callback($$v) {
        _vm.selectedItem = $$v;
      },
      expression: "selectedItem"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }