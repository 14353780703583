var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-expansion-panels', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v(" Hilfe ")]), _c('v-expansion-panel-content', [_c('v-simple-table', [_c('tbody', _vm._l(_vm.options, function (option, index) {
    return _c('tr', {
      key: 'option' + index
    }, [_c('td', {
      staticStyle: {
        "border": "0"
      }
    }, [_c('v-chip', {
      attrs: {
        "x-small": ""
      }
    }, [_vm._v(" ALT ")]), option.shift ? _c('v-icon', {
      attrs: {
        "x-small": ""
      }
    }, [_vm._v("mdi-plus")]) : _vm._e(), option.shift ? _c('v-chip', {
      attrs: {
        "x-small": ""
      }
    }, [_vm._v(" SHIFT ")]) : _vm._e(), option.enter ? _c('v-icon', {
      attrs: {
        "x-small": ""
      }
    }, [_vm._v("mdi-plus")]) : _vm._e(), option.enter ? _c('v-chip', {
      attrs: {
        "x-small": ""
      }
    }, [_vm._v(" ENTER ")]) : _vm._e(), option.key ? _c('v-icon', {
      attrs: {
        "x-small": ""
      }
    }, [_vm._v("mdi-plus")]) : _vm._e(), option.key ? _c('v-chip', {
      attrs: {
        "x-small": ""
      }
    }, [_c('v-icon', {
      attrs: {
        "x-small": ""
      }
    }, [_vm._v(_vm._s(option.key))])], 1) : _vm._e()], 1), _c('td', {
      staticStyle: {
        "border": "0"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("components.template.editor.".concat(option.text))) + " ")])]);
  }), 0)])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }