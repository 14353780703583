var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list', {
    staticStyle: {
      "scroll-behavior": "smooth",
      "overflow-y": "auto"
    },
    style: {
      height: _vm.height
    }
  }, _vm._l(_vm.documentTokens, function (pageTokens, pageIndex) {
    return _c('v-list-group', {
      key: 'list-group-page' + pageIndex,
      attrs: {
        "value": _vm.selectedPage === pageIndex
      },
      on: {
        "click": function click($event) {
          return _vm.setCurrentPage(pageIndex);
        }
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn() {
          return [_c('v-list-item-title', [_vm._v(_vm._s(_vm.$t("sign.DocumentTemplateDetailTokenList.page")) + " " + _vm._s(pageIndex + 1) + " ")])];
        },
        proxy: true
      }], null, true)
    }, _vm._l(pageTokens, function (documentToken, index) {
      return _c('v-list-item', {
        key: 'list-item-page' + pageIndex + 'document-token' + index,
        on: {
          "click": function click($event) {
            return _vm.setHighlightedToken(pageIndex, index);
          }
        }
      }, [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.getTokenText(documentToken.token)) + " ")])], 1);
    }), 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }