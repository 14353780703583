var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.hideButton ? _c('v-btn', {
    attrs: {
      "elevation": 0
    },
    on: {
      "click": _vm.open
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("create")) + " ")]) : _vm._e(), _vm.isDialogActive ? _c('confirm-action-dialog', {
    attrs: {
      "width": "1050",
      "persistent": true,
      "supressKeyboardActions": true,
      "title": _vm.$t('template.createTemplate'),
      "isDialogActive": _vm.isDialogActive,
      "rightText": _vm.$t('create'),
      "loading": _vm.isLoading,
      "fullscreen": _vm.fullscreen,
      "rightLoading": _vm.isLoading,
      "rightDisabled": !_vm.isFormValid || !_vm.template.content.body || !_vm.template.content.subject
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "leftClick": _vm.close,
      "close": _vm.close,
      "rightClick": _vm.onRightClick
    }
  }, [_c('v-form', {
    model: {
      value: _vm.isFormValid,
      callback: function callback($$v) {
        _vm.isFormValid = $$v;
      },
      expression: "isFormValid"
    }
  }, [_c('v-row', {
    staticClass: "pt-3"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('debug', [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('key'),
      "rules": _vm.rules(),
      "persistent-hint": "",
      "outlined": ""
    },
    model: {
      value: _vm.template.key,
      callback: function callback($$v) {
        _vm.$set(_vm.template, "key", $$v);
      },
      expression: "template.key"
    }
  })], 1), _c('v-text-field', {
    ref: "startField",
    attrs: {
      "label": _vm.$t('objects.template.meta.title'),
      "hint": _vm.$t('objects.template.meta.titleHint'),
      "rules": _vm.rules(_vm.RulesEnum.REQUIRED_RULE),
      "persistent-hint": "",
      "outlined": ""
    },
    model: {
      value: _vm.template.meta.title,
      callback: function callback($$v) {
        _vm.$set(_vm.template.meta, "title", $$v);
      },
      expression: "template.meta.title"
    }
  }), _c('v-select', {
    attrs: {
      "label": _vm.$t('objects.template.meta.language'),
      "hint": _vm.$t('objects.template.meta.languageHint'),
      "outlined": "",
      "items": _vm.availableLanguages,
      "rules": _vm.rules(),
      "filter": _vm.getAutocompleteFilter(function (e) {
        return [_vm.$t("enums.LanguageCodeEnum.".concat(e))];
      })
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm._f("getFlagEmojiByLanguage")(item)) + " " + _vm._s(_vm.$t("enums.LanguageCodeEnum.".concat(item))) + " ")];
      }
    }, {
      key: "selection",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm._f("getFlagEmojiByLanguage")(item)) + " " + _vm._s(_vm.$t("enums.LanguageCodeEnum.".concat(item))) + " ")];
      }
    }], null, false, 1530583388),
    model: {
      value: _vm.template.meta.language,
      callback: function callback($$v) {
        _vm.$set(_vm.template.meta, "language", $$v);
      },
      expression: "template.meta.language"
    }
  }), !_vm.partnerId ? _c('custom-field-boolean', {
    attrs: {
      "customField": {
        label: _vm.$t('objects.template.isPublic'),
        hint: _vm.$t('objects.template.isPublicHint')
      }
    },
    model: {
      value: _vm.template.isPublic,
      callback: function callback($$v) {
        _vm.$set(_vm.template, "isPublic", $$v);
      },
      expression: "template.isPublic"
    }
  }) : _vm._e(), _c('v-select', {
    attrs: {
      "value": _vm.isPlain,
      "label": _vm.$t('objects.template.meta.contentFormat'),
      "hint": _vm.$t('objects.template.meta.contentFormatHint'),
      "persistent-hint": "",
      "outlined": "",
      "items": [true, false]
    },
    on: {
      "change": function change($event) {
        _vm.isPlain = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("enums.MessageContentFormatEnum.plain")) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t("enums.MessageContentFormatEnum.html")) + " ")])];
      }
    }, {
      key: "selection",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("enums.MessageContentFormatEnum.plain")) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t("enums.MessageContentFormatEnum.html")) + " ")])];
      }
    }], null, false, 2120295964)
  }), _c('template-editor-subject', {
    attrs: {
      "partnerId": _vm.partnerId,
      "subject": _vm.template.content.subject,
      "addPlaceholderInSubject": true,
      "rules": _vm.rules(_vm.RulesEnum.REQUIRED_RULE)
    },
    on: {
      "update:subject": function updateSubject($event) {
        return _vm.$set(_vm.template.content, "subject", $event);
      }
    }
  }), _vm.isPlain ? _c('v-textarea', {
    attrs: {
      "outlined": "",
      "auto-grow": "",
      "label": _vm.$t('components.template.detail.body.body')
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('template-editor-add-placeholder', {
          staticClass: "mt-n2",
          attrs: {
            "partnerId": _vm.partnerId
          },
          on: {
            "addPlaceholder": function addPlaceholder($event) {
              _vm.template.content.body += $event;
            }
          }
        })];
      },
      proxy: true
    }], null, false, 234389967),
    model: {
      value: _vm.template.content.body,
      callback: function callback($$v) {
        _vm.$set(_vm.template.content, "body", $$v);
      },
      expression: "template.content.body"
    }
  }) : _c('template-editor', {
    key: 'template-editor' + _vm.isPlain,
    attrs: {
      "isPlain": _vm.isPlain,
      "readOnly": false,
      "editConfiguration": _vm.editConfiguration,
      "partnerId": _vm.partnerId,
      "label": _vm.$t('components.template.detail.body.body')
    },
    model: {
      value: _vm.template.content.body,
      callback: function callback($$v) {
        _vm.$set(_vm.template.content, "body", $$v);
      },
      expression: "template.content.body"
    }
  })], 1)], 1), _c('debug', [_vm._v(" " + _vm._s(_vm.template) + " ")])], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }