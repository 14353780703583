var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-simple', {
    attrs: {
      "title": "Allgemeine Geschäftsbedingungen"
    }
  }, [_c('v-container', {
    staticClass: "padded"
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('h1', {
    attrs: {
      "id": "allgemeine-geschaeftsbedingungen"
    }
  }, [_vm._v("Allgemeine Geschäftsbedingungen")]), _c('span', [_vm._v("(zuletzt aktualisiert am 19.03.2025)")]), _c('h2', {
    attrs: {
      "id": "einfuehrung"
    }
  }, [_vm._v("Einführung")]), _c('p', [_vm._v(" Die mint software GmbH, Marie-Curie-Str. 3, 49076 Osnabrück, E-Mail: info@mmmint.ai (\""), _c('strong', [_vm._v("Anbieter")]), _vm._v("\", \""), _c('strong', [_vm._v("wir")]), _vm._v("\" oder \""), _c('strong', [_vm._v("uns")]), _vm._v("\" genannt) hat eine Plattform mit Namen schadensmeldung.digital entwickelt („"), _c('strong', [_vm._v("Plattform")]), _vm._v("“). ")]), _c('ol', [_c('li', [_vm._v(" Diese Allgemeinen Nutzungsbedingungen („"), _c('strong', [_vm._v("Bedingungen")]), _vm._v("“) beschreiben deine Rechte und Pflichten als Nutzer unserer Plattform. Diese Bedingungen stellen eine rechtsverbindliche Vereinbarung zwischen dir als Nutzer und uns dar. ")]), _c('li', [_vm._v(" \""), _c('strong', [_vm._v("Du")]), _vm._v("\" oder “"), _c('strong', [_vm._v("Nutzer")]), _vm._v("” bezeichnet dich persönlich. Die Plattform richtet sich ausschließlich an volljährige Personen. Für die Nutzung unserer Plattform versicherst du, dass du mindestens 18 Jahre alt bist. ")]), _c('li', [_vm._v("Wenn du Fragen zur Plattform hast, kannst du uns unter info@mmmint.ai erreichen.")]), _c('li', [_vm._v(" Wir unterhalten weitere Bedingungen und Richtlinien, die diese Bedingungen ergänzen, wie z.B. unsere Datenschutzrichtlinie, die unsere Sammlung und Nutzung von persönlichen Daten beschreibt. ")])]), _c('p', [_vm._v(" Der Anbieter speichert diesen Vertragstext nach Vertragsschluss unter Beachtung des Datenschutzes. Diese Bedingungen sind jederzeit auf der "), _c('a', {
    attrs: {
      "href": "#/privacy/"
    }
  }, [_vm._v("Website")]), _vm._v(" in druckbarer Version abrufbar. ")]), _c('h2', {
    attrs: {
      "id": "vertragsgegenstand-plattform"
    }
  }, [_vm._v("Vertragsgegenstand Plattform")]), _c('p', [_vm._v(" Gegenstand dieses Vertrages ist die Nutzung der Plattform auf einer von uns betriebenen Website. Teilweise gelangst du über die Website eines unserer Partner auf unsere Plattform. Dann kann es sein, dass wir die optische Gestaltung der Plattform an das Corporate Design dieses Partners angelehnt haben; es kann insbesondere das Logo oder den Namenszug des Partners hinterlegt sein. Dennoch besteht der Vertrag über die Nutzung der Plattform zwischen dir und uns. Ausdrücklich nicht Gegenstand dieses Vertrages sind sämtliche Leistungen, die du mit einem unserer Partner über die Plattform vereinbarst. Wir stellen lediglich die Plattform für dich als Nutzer und für unsere Partner bereit. ")]), _c('p', [_vm._v(" Die Plattform ermöglicht es dir ein persönliches Nutzerkonto anzulegen (Ziffer 3). Gewisse Funktionen der Plattform können nur als registrierter Nutzer in Anspruch genommen werden. ")]), _c('p', [_vm._v(" Der Anbieter ist berechtigt, die Funktionen und Ausgestaltung der Plattform im Einzelnen jederzeit zu ändern, anzupassen und zu updaten, insbesondere aufgrund einer geänderten Rechtslage, technischer Entwicklungen oder zur Verbesserung der IT-Sicherheit. ")]), _c('p', [_vm._v("Die Nutzung der Plattform ist für dich kostenfrei.")]), _c('h2', {
    attrs: {
      "id": "anlegen-eines-nutzerkontos"
    }
  }, [_vm._v("Anlegen eines Nutzerkontos")]), _c('p', [_vm._v("Bei der Nutzung der Plattform besteht die Möglichkeit ein Nutzerkonto anlegen.")]), _c('p', [_vm._v(" Hierfür benötigen wir neben den im Rahmen der Plattformnutzung bereits gemachten Angaben zu deiner Person noch ein von dir frei gewähltes Passwort, welches den angegebenen Passwortanforderungen entspricht. ")]), _c('p', [_vm._v(" Die von dir mitgeteilten Daten bleiben im Nutzerkonto so lange gespeichert, bis du uns mitteilst, dass die Löschung einzelner Informationen oder des Nutzerkontos als Ganzes gewünscht ist. ")]), _c('p', [_vm._v(" Soweit sich persönliche Informationen ändern, bist du selbst für deren Aktualisierung verantwortlich. Alle Änderungen können online nach Anmeldung in deinem Nutzerkonto vorgenommen werden. ")]), _c('h2', {
    attrs: {
      "id": "verfuegbarkeit"
    }
  }, [_vm._v("Verfügbarkeit")]), _c('p', [_vm._v(" Der Anbieter bemüht sich um eine hohe durchschnittliche Verfügbarkeit der Plattform. Eine ununterbrochene Verfügbarkeit ist jedoch technisch nicht realisierbar. Es kann zu vorübergehenden Unterbrechungen oder Störungen der Plattform kommen. ")]), _c('h2', {
    attrs: {
      "id": "haftung"
    }
  }, [_vm._v("Haftung")]), _c('p', [_vm._v(" Der Anbieter haftet dem Kunden gegenüber bei Vorsatz und grober Fahrlässigkeit auf Schadensersatz oder Ersatz vergeblicher Aufwendungen. ")]), _c('p', [_vm._v(" In sonstigen Fällen haftet der Anbieter - soweit in Ziffer 4.3 nicht abweichend geregelt - nur bei Verletzung einer Vertragspflicht, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Einhaltung der Kunde regelmäßig vertrauen darf (sog. Kardinalpflicht), und zwar beschränkt auf den Ersatz des vorhersehbaren und typischen Schadens. In allen übrigen Fällen ist die Haftung des Anbieters - vorbehaltlich der Regelung in Ziffer 4.3 - ausgeschlossen. ")]), _c('p', [_vm._v(" Die Haftung des Anbieters für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit und nach dem Produkthaftungsgesetz bleibt von den vorstehenden Haftungsbeschränkungen und –ausschlüssen unberührt. ")]), _c('h2', {
    attrs: {
      "id": "schlussbestimmungen"
    }
  }, [_vm._v("Schlussbestimmungen")]), _c('p', [_vm._v(" Diese Bedingungen stellen die gesamte Vereinbarung zwischen dem Anbieter und dir in Bezug auf deinen Zugang zur Anbieter Plattform oder deren Nutzung dar und ersetzen alle vorherigen mündlichen oder schriftlichen Vereinbarungen zwischen dem Anbieter und dir. ")]), _c('p', [_vm._v(" Sofern es sich bei dir um einen Kaufmann, eine juristische Person des öffentlichen Rechts oder um ein öffentlich-rechtliches Sondervermögen handelt, ist ausschließlicher Gerichtsstand für alle Streitigkeiten aus diesen Bedingungen und aus Vertragsverhältnissen, die unter Einbeziehung dieser Bedingungen geschlossen wurden, der Sitz von der mint software GmbH. ")]), _c('p', [_vm._v(" Es findet ausschließlich das Recht der Bundesrepublik Deutschland Anwendung unter Ausschluss des UN-Kaufrechts. ")]), _c('p', [_vm._v(" Die Parteien sind nicht berechtigt Ansprüche oder sonstige Rechte aus diesen Bedingungen ohne Zustimmung der jeweils anderen Parteien zu übertragen, zu verpfänden oder in sonstiger Weise zu belasten. ")]), _c('p', [_vm._v(" Sollte eine Bestimmung dieses Vertrages ganz oder teilweise nichtig, unwirksam oder undurchsetzbar sein oder werden, wird die Wirksamkeit und Durchsetzbarkeit aller übrigen verbleibenden Bestimmungen davon nicht berührt. Anstelle einer nichtigen, unwirksamen oder undurchsetzbaren Bestimmung treten, soweit vorhanden, die gesetzlichen Vorschriften. Soweit dies für eine Vertragspartei eine unzumutbare Härte darstellen würde, wird der Vertrag jedoch im Ganzen unwirksam. ")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }