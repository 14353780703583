var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-slide-x-reverse-transition', [_c('v-sheet', {
    style: _vm.style
  }, [!_vm.hideButton ? _c('div', [_c('v-btn', {
    attrs: {
      "icon": "",
      "large": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")])], 1), _vm._t("menu")], 2) : _vm._e(), !_vm.hideButton ? _c('v-divider', {
    staticClass: "mb-0"
  }) : _vm._e(), _c('card', {
    style: _vm.isScrollable ? "height:100%; overflow-y: auto" : "",
    attrs: {
      "margin": "0",
      "title": _vm.title,
      "hideTitle": _vm.hideTitle || !_vm.title,
      "subtitle": _vm.subtitle,
      "loading": _vm.loading,
      "showGradient": _vm.showGradient,
      "outlined": false
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_vm._t("actions")];
      },
      proxy: true
    }, {
      key: "header",
      fn: function fn() {
        return [_vm._t("header")];
      },
      proxy: true
    }, {
      key: "subtitle",
      fn: function fn() {
        return [_vm._t("subtitle")];
      },
      proxy: true
    }], null, true)
  }, [_vm._t("default")], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }