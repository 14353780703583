var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    style: "height: ".concat(_vm.height, ";")
  }, [!_vm.readOnly ? _c('v-row', {
    staticStyle: {
      "height": "35px"
    }
  }, [_c('v-spacer'), !_vm.isPlain ? _c('template-editor-actions', {
    ref: "editorActions",
    staticClass: "mr-3",
    staticStyle: {
      "z-index": "1"
    },
    attrs: {
      "partnerId": _vm.partnerId,
      "editor": _vm.editor,
      "editConfig": _vm.editConfig
    },
    on: {
      "click": _vm.handleEdit,
      "addPlaceholder": _vm.addPlaceholderToBody
    }
  }, [_vm._t("default")], 2) : _c('v-card', {
    staticClass: "mr-3",
    attrs: {
      "outlined": "",
      "height": "30"
    }
  }, [_vm._t("default")], 2)], 1) : _vm._e(), _c('v-card', {
    staticStyle: {
      "height": "70%"
    },
    attrs: {
      "flat": ""
    }
  }, [_c('div', {
    staticClass: "mb-4",
    staticStyle: {
      "background-color": "inherit"
    }
  }, [_c('div', {
    staticStyle: {
      "position": "absolute",
      "height": "0"
    }
  }, [_c('small', {
    class: _vm.dynamicLabelClasses,
    staticStyle: {
      "position": "relative",
      "left": "10px",
      "bottom": "11px",
      "z-index": "1"
    },
    style: "".concat(_vm.label ? 'padding: 2px;' : '', " background-color: ").concat(!_vm.$vuetify.theme.dark ? 'white;' : 'rgb(30, 30, 30)', ";\n            ")
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")])])]), _vm.isPlain ? _c('v-textarea', {
    attrs: {
      "readonly": _vm.readOnly,
      "outlined": _vm.outlined,
      "hint": _vm.hint,
      "persistent-hint": _vm.persistentHint
    },
    model: {
      value: _vm.templateLocal,
      callback: function callback($$v) {
        _vm.templateLocal = $$v;
      },
      expression: "templateLocal"
    }
  }) : _c('editor-content', {
    ref: "editor",
    class: _vm.dynamicTipTapClasses,
    attrs: {
      "editor": _vm.editor
    },
    on: {
      "mouseover": function mouseover($event) {
        return _vm.setMouseOver(true);
      }
    }
  }), !_vm.readOnly && !_vm.hideCounter ? _c('v-card-actions', {
    staticClass: "mt-n2 mb-n12"
  }, [_c('v-spacer'), _c('small', {
    style: _vm.bodyCounterStyle
  }, [_vm._v(" " + _vm._s(_vm.bodyLength) + "/50000 ")])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }