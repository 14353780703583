var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "text-truncate "
  }, [_c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('div', [_vm.hasOpenDetailListener() ? _c('a', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.emitOpenDetail.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item.title) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.item.title) + " ")])]), _c('v-spacer')], 1)]), !_vm.small ? _c('v-list-item-subtitle', [_c('div', {
    staticClass: "d-flex align-items-start"
  }, [_vm._v(" " + _vm._s(_vm.item.timestamp.createdReadable) + " ")])]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }