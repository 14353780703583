var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.documentToken && _vm.page ? _c('v-card', {
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("sign.ReportDocumentSignRequestStepperForm.edit")) + " "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', {
    on: {
      "click": _vm.unselect
    }
  }, [_vm._v("mdi-close")])], 1)], 1), _c('v-card-text', [_c('v-form', [_c('v-row', [_c('debug', [_vm._v(" " + _vm._s(_vm.documentToken) + " " + _vm._s(_vm.isEditValue && !_vm.getIsSignature(_vm.documentToken.token) || _vm.getIsTextField(_vm.documentToken.token)) + " " + _vm._s(_vm.isEditValue && !_vm.getIsSignature(_vm.documentToken.token)) + " " + _vm._s(_vm.isEditValue) + " " + _vm._s(_vm.getIsTextField(_vm.documentToken.token)) + " ")]), _vm.isEditValue && !_vm.getIsSignature(_vm.documentToken.token) || _vm.getIsTextField(_vm.documentToken.token) ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "caption"
  }, [_vm._v(" " + _vm._s(_vm.$t("sign.DocumentTemplateDetailTokenList.inputHint")) + " ")]), _c('v-textarea', {
    staticClass: "pt-4",
    attrs: {
      "append-icon": !_vm.documentToken.value ? 'mdi-alert' : '',
      "autofocus": "",
      "outlined": "",
      "rows": 1,
      "auto-grow": "",
      "label": _vm.documentToken.title || _vm.getTokenText(_vm.documentToken.token),
      "hint": _vm.documentToken.description,
      "persistent-hint": "",
      "readonly": _vm.disabled
    },
    model: {
      value: _vm.documentToken.value,
      callback: function callback($$v) {
        _vm.$set(_vm.documentToken, "value", $$v);
      },
      expression: "documentToken.value"
    }
  })], 1) : _c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('template-editor-placeholder-dropdown', {
    key: 'placeholderdropdown' + _vm.documentToken.token,
    attrs: {
      "disabled": _vm.getIsSignature(_vm.documentToken.token) || _vm.disabled,
      "label": _vm.documentToken.title,
      "hint": _vm.getIsSignature(_vm.documentToken.token) ? _vm.$t('sign.DocumentTemplateDetailTokenList.signatureHint') : _vm.documentToken.description || _vm.$t('sign.DocumentTemplateDetailTokenList.tokenHint'),
      "persistent-hint": 'true',
      "context": {
        report: true,
        partner: true,
        sign: true
      },
      "initialOption": _vm.getTokenText(_vm.documentToken.token)
    },
    on: {
      "change": function change(v) {
        return _vm.updateToken(_vm.documentToken, v);
      }
    }
  })], 1), _vm.getIsSignature(_vm.documentToken.token) ? _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm.getIsSignature(_vm.documentToken.token) ? _c('v-checkbox', {
    attrs: {
      "input-value": _vm.isSignatureWithQR(_vm.documentToken),
      "label": "QR-Code",
      "true-value": true,
      "false-value": false
    },
    on: {
      "change": function change($event) {
        return _vm.change($event, _vm.documentToken);
      }
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('small', [_vm._v(" " + _vm._s(_vm.$t("QR-Code")) + " ")])];
      },
      proxy: true
    }], null, false, 3230851052)
  }) : _vm._e()], 1) : _vm._e(), !_vm.getIsSignature(_vm.documentToken.token) && _vm.isSignatureFormActive ? _c('v-col', {
    staticClass: "mb-n2 mt-n4",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": _vm.$t('sign.DocumentTemplateDetailTokenList.isEditable'),
      "disabled": _vm.disabled
    },
    model: {
      value: _vm.documentToken.isEditable,
      callback: function callback($$v) {
        _vm.$set(_vm.documentToken, "isEditable", $$v);
      },
      expression: "documentToken.isEditable"
    }
  }), _vm.documentToken.isEditable ? _c('v-checkbox', {
    staticClass: "mt-n4",
    attrs: {
      "label": _vm.$t('sign.DocumentTemplateDetailTokenList.isRequired'),
      "disabled": _vm.disabled
    },
    model: {
      value: _vm.documentToken.isRequired,
      callback: function callback($$v) {
        _vm.$set(_vm.documentToken, "isRequired", $$v);
      },
      expression: "documentToken.isRequired"
    }
  }) : _vm._e()], 1) : _vm._e(), !_vm.getIsSignature(_vm.documentToken.token) ? _c('v-col', {
    staticClass: "mb-n4",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-n4",
    attrs: {
      "label": _vm.$t('sign.DocumentTemplateDetailTokenList.descriptionTitle'),
      "placeholder": _vm.$t('sign.DocumentTemplateDetailTokenList.descriptionTitleExplanation'),
      "dense": "",
      "outlined": "",
      "disabled": _vm.disabled
    },
    model: {
      value: _vm.documentToken.title,
      callback: function callback($$v) {
        _vm.$set(_vm.documentToken, "title", $$v);
      },
      expression: "documentToken.title"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('sign.DocumentTemplateDetailTokenList.description'),
      "placeholder": _vm.$t('sign.DocumentTemplateDetailTokenList.descriptionExplanation'),
      "dense": "",
      "outlined": "",
      "disabled": _vm.disabled
    },
    model: {
      value: _vm.documentToken.description,
      callback: function callback($$v) {
        _vm.$set(_vm.documentToken, "description", $$v);
      },
      expression: "documentToken.description"
    }
  })], 1) : _vm._e(), _vm.isSignatureFormActive ? _c('v-col', {
    staticClass: "mb-n4",
    attrs: {
      "cols": "12"
    }
  }, [!_vm.getIsSignature(_vm.documentToken.token) ? _c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "append-icon": "mdi-format-font-size-increase",
      "label": _vm.$t('sign.DocumentTemplateDetailTokenList.fontSize') + ' (pt)',
      "type": "number",
      "min": "1",
      "max": "40",
      "value": Math.round(_vm.documentToken.coordinates.fontSize),
      "disabled": _vm.disabled
    },
    on: {
      "change": function change(v) {
        return _vm.documentToken.coordinates.fontSize = Number(v);
      }
    }
  }) : _vm._e()], 1) : _vm._e(), _c('v-col', {
    staticClass: "mb-n4",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "mr-1",
    attrs: {
      "dense": "",
      "outlined": "",
      "append-icon": "mdi-arrow-left-right",
      "label": _vm.$t('sign.DocumentTemplateDetailTokenList.x') + ' (mm)',
      "type": "number",
      "min": "0",
      "max": _vm.page.pdfWidth - _vm.documentToken.coordinates.x,
      "value": Math.round(_vm.documentToken.coordinates.x),
      "disabled": _vm.disabled
    },
    on: {
      "change": function change(v) {
        return _vm.documentToken.coordinates.x = Number(v);
      }
    }
  })], 1), _c('v-col', {
    staticClass: "mb-n4",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "ml-1",
    attrs: {
      "dense": "",
      "outlined": "",
      "append-icon": "mdi-arrow-up-down",
      "label": _vm.$t('sign.DocumentTemplateDetailTokenList.y') + ' (mm)',
      "type": "number",
      "min": "0",
      "max": _vm.page.pdfHeight - _vm.documentToken.coordinates.h,
      "value": Math.round(_vm.documentToken.coordinates.y),
      "disabled": _vm.disabled
    },
    on: {
      "change": function change(v) {
        return _vm.documentToken.coordinates.y = Number(v);
      }
    }
  })], 1), _c('v-col', {
    staticClass: "mb-n4",
    attrs: {
      "cols": "6"
    }
  }, [!_vm.getIsSignature(_vm.documentToken.token) ? _c('v-text-field', {
    staticClass: "mr-1",
    attrs: {
      "dense": "",
      "outlined": "",
      "append-icon": "mdi-unfold-less-vertical",
      "label": _vm.$t('sign.DocumentTemplateDetailTokenList.w') + ' (mm)',
      "type": "number",
      "min": "1",
      "max": _vm.page.pdfWidth - _vm.documentToken.coordinates.x,
      "value": Math.round(_vm.documentToken.coordinates.w),
      "disabled": _vm.disabled
    },
    on: {
      "change": function change(v) {
        return _vm.documentToken.coordinates.w = Number(v);
      }
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticClass: "mb-n4",
    attrs: {
      "cols": "6"
    }
  }, [!_vm.getIsSignature(_vm.documentToken.token) ? _c('v-text-field', {
    staticClass: "ml-1",
    attrs: {
      "dense": "",
      "outlined": "",
      "append-icon": "mdi-unfold-less-horizontal",
      "label": _vm.$t('sign.DocumentTemplateDetailTokenList.h') + ' (mm)',
      "type": "number",
      "min": "1",
      "max": _vm.page.pdfHeight - _vm.documentToken.coordinates.y,
      "value": Math.round(_vm.documentToken.coordinates.h),
      "disabled": _vm.disabled
    },
    on: {
      "change": function change(v) {
        return _vm.documentToken.coordinates.h = Number(v);
      }
    }
  }) : _vm._e()], 1)], 1)], 1)], 1), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "elevation": 0,
      "outlined": ""
    },
    on: {
      "click": _vm.unselect
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("sign.DocumentTemplateDetailTokenList.discard")) + " ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "elevation": 0,
      "color": "error",
      "outlined": "",
      "disabled": _vm.disabled
    },
    on: {
      "click": function click($event) {
        return _vm.removeDocumentToken(_vm.documentToken);
      }
    }
  }, [_vm.documentToken.token ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("sign.DocumentTemplateDetailTokenList.remove")) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t("sign.DocumentTemplateDetailTokenList.discard")) + " ")])]), _c('v-btn', {
    attrs: {
      "elevation": 0,
      "color": "success",
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("sign.DocumentTemplateDetailTokenList.confirm")) + " ")])], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }