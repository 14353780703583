var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "height": "100%",
      "overflow-y": "auto",
      "overflow-x": "hidden"
    }
  }, [_c('paginated-table', {
    attrs: {
      "store": _vm.store,
      "predefinedFilter": _vm.store.commonPredefinedFilter,
      "baseQuery": {
        partnerId: _vm.partnerId
      },
      "headers": _vm.headers,
      "showSelect": false,
      "selectedItems": _vm.selectedItems,
      "controlElements": _vm.controlElements
    },
    on: {
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selectedItems = $event;
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selectedItems = $event;
      },
      "click:row": function clickRow($event) {
        return _vm.selectItemAndNext($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item.isTemplate",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticStyle: {
            "display": "flex"
          }
        }, [_c('v-tooltip', {
          attrs: {
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('v-icon', _vm._g({}, on), [_vm._v(" " + _vm._s(item.isSigned ? "mdi-signature-freehand" : item.isTemplate ? "mdi-file-multiple-outline" : "mdi-file-document-outline") + " ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" " + _vm._s(item.isSigned ? _vm.$t("sign.DocumentDetail.signed") : item.isTemplate ? _vm.$t("sign.DocumentDetail.template") : _vm.$t("sign.DocumentDetail.file")) + " ")])])], 1)];
      }
    }])
  }), _vm.preview ? _c('confirm-action-dialog', {
    attrs: {
      "title": _vm.$t('partner.excelimport.file'),
      "isDialogActive": _vm.preview,
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "loading": _vm.isLoadingDetails
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.preview = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.preview = $event;
      },
      "leftClick": _vm.closePreview,
      "rightClick": _vm.confirmPreview,
      "close": _vm.closePreview
    }
  }, [_c('vue-picture-swipe', {
    staticClass: "mx-2 mt-4 mb-2",
    attrs: {
      "items": _vm.mapToImage,
      "options": {
        shareEl: false,
        rotationOn: true
      }
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }