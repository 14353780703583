import { render, staticRenderFns } from "./RefsGroup.vue?vue&type=template&id=5ebf2664&scoped=true&"
import script from "./RefsGroup.vue?vue&type=script&lang=ts&"
export * from "./RefsGroup.vue?vue&type=script&lang=ts&"
import style0 from "./RefsGroup.vue?vue&type=style&index=0&id=5ebf2664&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ebf2664",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VListItem,VListItemContent,VListItemSubtitle,VListItemTitle,VSkeletonLoader})
