var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm.hasOpenDetailListener() ? _c('a', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.emitOpenDetail.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item.title) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.item.titleReadable || _vm.item.title) + " ")])]), !_vm.small && (_vm.item.startDate || _vm.item.endDate) ? _c('v-list-item-subtitle', [_c('span', [_vm._v(" " + _vm._s(_vm._f("simpleDoubleDigitDate")(_vm.item.startDate)) + " ")]), _vm.item.startDate && _vm.item.endDate ? _c('span', [_vm._v(" - ")]) : _vm._e(), _c('span', [_vm._v(" " + _vm._s(_vm._f("simpleDoubleDigitDate")(_vm.item.endDate)) + " ")])]) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }