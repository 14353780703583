var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "checkbox-container mb-8"
  }, [_c('fieldset', {
    staticClass: "rounded"
  }), _c('v-checkbox', {
    staticClass: "mt-0",
    class: !_vm.dense ? 'pa-4' : 'pa-2',
    attrs: {
      "hide-details": "auto",
      "rules": _vm.requiredRule,
      "disabled": _vm.disabled,
      "loading": _vm.loading,
      "hint": _vm.customField.hint,
      "label": _vm.customField.label,
      "persistent-hint": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn(_ref) {
        var message = _ref.message;
        return [_c('span', {
          domProps: {
            "innerHTML": _vm._s(message)
          }
        })];
      }
    }]),
    model: {
      value: _vm.input,
      callback: function callback($$v) {
        _vm.input = $$v;
      },
      expression: "input"
    }
  }), _vm.loading ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": "",
      "height": 1
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }