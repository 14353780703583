var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t("sign.SignInitializeCard.section2")) + " ")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "autofocus": "",
      "label": _vm.$t('components.partner.PartnerReportInitializeCard.firstName'),
      "rules": _vm.nameRules,
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.firstName,
      callback: function callback($$v) {
        _vm.firstName = $$v;
      },
      expression: "firstName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": _vm.$t('components.partner.PartnerReportInitializeCard.lastName'),
      "rules": _vm.nameRules,
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.lastName,
      callback: function callback($$v) {
        _vm.lastName = $$v;
      },
      expression: "lastName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t("sign.SignInitializeCard.section4")) + " ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.baseReportUrls,
      "item-text": "type",
      "item-value": "id",
      "label": _vm.$t('components.partner.PartnerReportInitializeCard.url'),
      "outlined": "",
      "dense": "",
      "return-object": "",
      "single-line": ""
    },
    model: {
      value: _vm.baseReportUrl,
      callback: function callback($$v) {
        _vm.baseReportUrl = $$v;
      },
      expression: "baseReportUrl"
    }
  })], 1)], 1)], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t("sign.SignInitializeCard.section5")) + " ")])]), _c('v-col', [_c('v-text-field', {
    ref: "link",
    attrs: {
      "outlined": "",
      "readonly": "",
      "value": _vm.createUrlWithQuery,
      "append-icon": "mdi-content-copy",
      "label": _vm.$t('components.partner.PartnerReportInitializeCard.hint'),
      "persistent-hint": "",
      "data-test-copy-link": ""
    },
    on: {
      "click:append": _vm.copyLinkToClipboard
    }
  })], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "mt-auto pb-8"
  }, [_vm._t("left"), _c('v-btn', {
    staticStyle: {
      "width": "30%"
    },
    attrs: {
      "elevation": 0,
      "x-large": ""
    },
    on: {
      "click": _vm.clear
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("sign.SignInitializeCard.delete")) + " ")]), _c('v-spacer'), _c('v-btn', {
    staticStyle: {
      "width": "60%"
    },
    attrs: {
      "color": "success",
      "elevation": 0,
      "x-large": ""
    },
    on: {
      "click": _vm.goToSignPage
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("views.report.my.MySignatureView.signable")) + " ")])], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }